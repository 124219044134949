<template>
  <div class="container py-5">
    <p class="fw-bold">第十五屆美沃斯醫療與美容科技博覽會</p>
    
    <p>時間：2023 09/01-03</p>
    <p>地點：大連國際會議中心</p>
    <p>展位：A83 B84</p>
    <p>
     第十五屆美沃斯國際醫學美容大會暨東北亞醫美大會中日韓峰會將於2023年9月1-3日在大連國際會議中心舉行。<br><br>

山西銘伊恆美作為參展商，攜荷蘭OBSERV皮膚檢測儀和由山西鍶為智能科技有限公司研發並生產的伊諾鍶黃金微針，在A83/B84展位亮相迎接行業同仁。<br><br>

每年舉辦的美沃斯國際醫學美容大會、CMCIS中國醫美產業峰會及美沃斯醫療與美容科技博覽會，涵蓋學術技術、經營管理與科技產品等領域，全面覆蓋官產學研，集學術、技術、藝術、管理、時尚、文化於一身，吸引了醫美行業來自美國、歐洲、日本、韓國、台灣等眾多行業精英及供應商廣泛參與，年參會人數近20000人，參展商數百家，成為行業規模和影響力很大的盛會之一。<br><br>

美沃斯，為醫美職業賦能。美沃斯始終聚焦醫美產業，以“國際化、全產業鏈、跨界”為總體定位，以“為醫美職業賦能”為品牌使命，力求成為橫跨國際與國內、融合線上與線下的全球醫美全產業鏈信息與服務共享平台。<br><br>
    </p>

    <img
      class="img-sm rounded"
      src="@/assets/images/zh-TW/news/new_28_1.jpg"
    />

  </div>
</template>
<script>
export default {};
</script>
